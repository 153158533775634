.modal-country {
    position: relative;
    flex-direction: column;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;

    .modal-country__header {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 4rem);
        gap: 1.5rem;
        align-items: center;
        z-index: 7;
        padding: 0.75rem 0 0.75rem 0.75rem;
        background: var(--color--white);

        .modal-country__header__link {
            position: relative;
            display: grid;
            grid-template-columns: minmax(0, 2rem) minmax(0, 1fr);
            gap: 0.125rem 1rem;
            text-decoration: none;
            padding: 0;
            border-radius: 0.25rem;
            transition: var(--transition);

            &::after {
                content: "\e909";
                font-family: 'whentoexplore' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: var(--color--white);
                font-size: 1.5rem;
                position: absolute;
                right: 2rem;
                top: calc(50% - 0.75rem);
                transition: var(--transition);
            }

            @media screen and (min-width: 1024px) {
                padding: 0.75rem;

                &:hover,
                &:focus-visible {
                    background-color: rgba(var(--color--primary-rgb), 0.1);

                    .modal-country__header__title {
                        color: var(--color--primary);
                    }

                    &::after {
                        right: 1rem;
                        color: var(--color--primary);
                    }
                }
            }
        }

        .modal-country__header__flag {
            width: 100%;
            background-color: #fff;
            height: 1.375rem;
            object-fit: cover;
            border-radius: 0.125rem;
            overflow: hidden;
            grid-row-start: 1;
            grid-row-end: 3;
            align-self: center;
        }

        .modal-country__header__title {
            order: 0;
            font-size: 1.125rem;
            margin: 0;
            font-weight: 600;
            transition: var(--transition);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .modal-country__header__region {
            grid-column-start: 2;
            margin: 0;
            font-size: 0.625rem;
            color: var(--color--grey);
        }

        .modal-country-modal__close {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            order: 1;
            font-size: 1.5rem;
            line-height: 1;
            background-color: transparent;
            border: none;
            cursor: pointer;

            i {
                transition: var(--transition);
                color: var(--color--grey);
            }

            &:hover,
            &:focus-visible {
                i {
                    color: var(--color--primary);
                }
            }
        }

        @media screen and (max-width: 1023px) {
            height: 4rem;
            padding: 0.875rem 0 0.875rem 1.25rem;
        }
    }

    .modal-country__body {
        background-color: var(--color--grey-xlight);
        border-top: 0.062rem solid var(--color--grey-light);
        overflow: auto;
    }

    .modal-country-dashboard {
        display: flex;
        flex-direction: column;
        margin: 1.25rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid var(--color--grey-light);
        overflow: hidden;

        .modal-country-dashboard__activities {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .modal-country-dashboard__body {
            display: flex;
            flex-direction: column;
            background-color: var(--color--white);
        }

        .modal-country-dashboard__info {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .country-card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem;
        background-color: var(--color--white);
        border: .5px solid var(--color--grey-light);

        h3,
        h4,
        p {
            margin: 0;
        }

        .country-card__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
        }

        .country-card__title {
            font-size: 0.75rem;
            color: var(--color--grey-medium);
            font-weight: 400;
        }

        .country-card__header__value {
            font-size: 2rem;
            font-weight: 700;
        }

        .country-card__header__unit {
            font-size: 0.75rem;
            color: var(--color--grey-medium);
        }

        .country-card__range {
            position: relative;
            width: 100%;
            height: 0.125rem;
            border-radius: 0.125rem;
            background-color: var(--color--grey-light);

            &.-value {
                position: absolute;
                background: var(--color--primary);
                background: linear-gradient(90deg, var(--color--primary) 0%, var(--color--primary-dark) 100%);
                top: 0;
                left: 0;
            }
        }

        .country-card__icon {
            display: grid;
            grid-template-columns: minmax(0, 2.5rem) minmax(0, 1fr);
            align-items: center;
            gap: 0.75rem;

            i {
                position: relative;
                width: 100%;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 0.5rem;
                font-size: 1.25rem;
                color: var(--color--primary);

                &::after {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--color--primary);
                    opacity: 0.1;
                }
            }

            .country-card__icon__title {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
            }

            .country-card__icon__value {
                font-size: 1.125rem;
                font-weight: 600;
            }

            .country-card__icon__text {
                font-size: 0.75rem;
                color: var(--color--grey-medium);
                
                &:empty {
                    display: none;
                }
            }
        }

        &.-range {
            gap: 0.75rem;

            .country-card__title {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 0.625rem;

                i {
                    font-size: 1.5rem;
                    color: var(--color--primary-dark);
                }

                span {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: var(--color--grey-medium);
                }
            }

            .country-card__header__value {
                font-size: 1.25rem;
            }
        }

        &.-global {
            position: relative;
            grid-column: span 2;
            padding: 1rem 1.25rem;
            gap: 0;
            border-radius: 0;
            order: 0 !important;
            z-index: 2;
            border-top: 0;
            border-left: 0;
            border-right: 0;

            .country-card__title {
                display: grid;
                grid-template-columns: 2rem minmax(0, 1fr);
                gap: 0.25rem 0.75rem;
                font-size: 0.75rem;

                span {
                    font-size: 0.625rem;

                    &.country-card__month {
                        font-weight: 700;
                        color: var(--color--grey-dark);
                        font-size: 1rem;
                    }
                }

                i {
                    font-size: 2rem;
                    color: var(--color--primary);
                    grid-row: span 2;
                }
            }

            .country-card__body {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
            }

            .country-card__range {

                &.-value {
                    background: linear-gradient(90deg, var(--color--global-75) 0%, var(--color--primary) 100%);
                }
            }

            .country-card__header__value {
                font-size: 1.75rem;
                color: var(--color--primary);
            }
        }

        &.-ski {
            --color--primary: var(--color--ski-70);
            --color--primary-dark: var(--color--ski-80);
        }

        &.-beach {
            --color--primary: var(--color--beach-70);
            --color--primary-dark: var(--color--beach-80);
        }

        &.-surf {
            --color--primary: var(--color--surf-70);
            --color--primary-dark: var(--color--surf-80);
        }

        &.-hiking {
            --color--primary: var(--color--hiking-70);
            --color--primary-dark: var(--color--hiking-80);
        }

        &.-weather {
            &.-Hot {
                --color--primary: var(--color--beach-80);
            }

            &.-Mild {
                --color--primary: var(--color--beach-70);

                .icon-hot {
                    &::before {
                        content: "\e98f";
                    }
                }
            }

            &.-Cold {
                --color--primary: var(--color--ski-75);

                .icon-hot {
                    &::before {
                        content: "\e98d";
                    }
                }
            }
        }

        &.-water {
            &.-Hot {
                --color--primary: var(--color--beach-80);
            }

            &.-Mild {
                --color--primary: var(--color--beach-70);
            }

            &.-Cold {
                --color--primary: var(--color--ski-75);
            }
        }

        &.-sunshine {
            &.-Bright {
                --color--primary: var(--color--beach-70);
            }

            &.-Sunny {
                --color--primary: var(--color--hiking-70);

                .icon-sun {
                    &::before {
                        content: "\e987";
                    }
                }
            }

            &.-Cloudy {
                --color--primary: var(--color--ski-80);

                .icon-sun {
                    &::before {
                        content: "\e983";
                    }
                }
            }
        }

        &.-rainfall {
            &.-Rainy {
                --color--primary: var(--color--ski-75);
            }

            &.-Moderate {
                --color--primary: var(--color--ski-70);

                .icon-cloud-rain {
                    &::before {
                        content: "\e984";
                    }
                }
            }

            &.-Dry {
                --color--primary: var(--color--hiking-70);

                .icon-cloud-rain {
                    &::before {
                        content: "\e983";
                    }
                }
            }
        }

        &.-cost {
            .icon-dollar-sign {
                font-size: 1.125rem;
            }

            &.-Expensive {
                --color--primary: var(--color--beach-75);

                .icon-dollar-sign {
                    font-size: 1rem;

                    &::before {
                        content: "\e97e";
                    }
                }
            }

            &.-Moderate {
                --color--primary: var(--color--ski-70);

                .icon-dollar-sign {
                    &::before {
                        content: "\e97f";
                    }
                }
            }

            &.-Budget {
                --color--primary: var(--color--hiking-70);
            }
        }

        &.-tourism {
            &.-Crowded {
                --color--primary: var(--color--beach-75);
            }

            &.-Moderate {
                --color--primary: var(--color--ski-70);

                .icon-chevrons-up {
                    &::before {
                        content: "\e91d";
                    }
                }
            }

            &.-Calm {
                --color--primary: var(--color--hiking-70);

                .icon-chevrons-up {

                    &::before {
                        content: "\e917";
                    }
                }
            }
        }

        &.-disabled {
            --color--primary: var(--color--grey-medium);
            --color--primary-dark: var(--color--grey-medium);

            opacity: 0.5;
        }

        &:not(.-global).-less {
            --color--primary: var(--color--grey-medium);
            --color--primary-dark: var(--color--grey-medium);
        }
        
        &.-high:not(.-global) {
            border-color: var(--color--primary);

            &::after {
                content: "\e918";
                font-family: 'whentoexplore' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                background-color: var(--color--primary);
                color: var(--color--white);
                font-size: 0.875rem;
                padding: 0.375rem;
                border-radius: 50%;
                position: absolute;
                top: -0.375rem;
                right: -0.375rem;
                box-shadow: var(--shadow-small);
                z-index: 3;
            }
        }

        &.-events {
            display: grid;
            grid-template-columns: minmax(0, 6.5rem) minmax(0, 1fr);
            align-items: center;
            gap: 1rem;
            padding: 1rem;

            img {
                width: 100%;
                height: 6.5rem;
                border-radius: 0.5rem;
                object-fit: cover;
                object-position: center;
            }

            .country-card__body {
                display: flex;
                flex-direction: column;
                gap: 0.375rem;
            }

            .country-card__title {
                font-size: 1.125rem;
                font-weight: 600;
                color: var(--color--primary-dark);
            }

            .country-card__place {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                color: var(--color--grey-dark);
                font-size: 0.75rem;

                &::before {
                    content: "\e942";
                    font-family: 'whentoexplore' !important;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                &:empty {
                    display: none;
                }
            }

            .country-card__description {
                color: var(--color--grey-dark);
                font-size: 0.875rem;
                margin-top: 0.5rem;
                line-height: 1.3;
            }
        }

        &.-Unavailable {
            --color--primary: var(--color--grey-medium);
            --color--primary-dark: var(--color--grey-dark);

            opacity: 0.5;

            .country-card__icon__text {
                display: none;
            }
        }

        @media screen and (min-width: 1024px) {
            gap: 1rem;
            padding: 1rem;
        }
    }

    .modal-country__footer {
        box-shadow: var(--shadow-small);

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 500;
            color: var(--color--primary-dark);
            transition: var(--transition);

            i {
                font-size: 1.25rem;
                transition: var(--transition);
            }

            &:hover,
            &:focus-visible {
                color: var(--color--primary);

                i {
                    color: var(--color--primary);
                }
            }
        }
    }
}
